<template>
   <dashboard :alertModal="alertModal" />
</template>

<script>

import Dashboard from './dashboard/Dashboard.vue';

export default {
  components: {
    Dashboard,

  },
  data() {
    return {
      alertModal: false,
      previousRoute: null, 
    };
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log('De qual rota você veio:', from.name); 
    next(vm => {
      vm.previousRoute = from.name;
      if(from.name === 'login' && vm.company.alert_modal ){
        vm.alertModal = true;
      }
    });
    
  }
};
</script>

