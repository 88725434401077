<template>
  <div>
    <b-row>
      <b-col cols="12">
        <dashboard-component-form />
        <b-modal
          v-model="alertModal"
          centered
          title="Atenção: Prazo para Download dos Documentos"
          hide-footer
          :header-bg-variant="'warning'"
          no-close-on-backdrop
          hide-header-close
        >
          <b-alert variant="warning" show >
            <p class="justified-text">
            Informamos que os documentos disponíveis para download ficarão
            acessíveis por 30 dias a partir da data de disponibilização. Após
            esse período, os documentos serão removidos automaticamente e não
            poderão mais ser recuperados. Por isso, recomendamos que faça o
            download dos documentos o quanto antes para garantir que tenha
            acesso a eles dentro do prazo. Caso precise de ajuda para realizar o
            download ou tenha dúvidas, entre em contato conosco!
          </p>
          </b-alert>
          <b-col class="justify-content-end d-flex p-1">
            <b-button
              class="ml-1 variant-primary"
              variant="warning"
              @click="alertModal = false"
              >Ok</b-button
            >
          </b-col>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BAlert,
  BButton,
  BModal,
  BCol,
  BCard,
  BCardText,
  BLink,
} from 'bootstrap-vue';

import DashboardComponentForm from './components/DashboardComponentForm.vue';

export default {
  name: 'dashboard',
  components: {
    BCol,
    BModal,
    BAlert,
    BButton,
    BRow,
    BCard,
    BCardText,
    BLink,
    DashboardComponentForm,
  },
  props:{
    alertModal: { type: Boolean, default: false },
  },

};
</script>

<style scoped>
/* Estilo para justificar o texto */
.justified-text {
  text-align: justify;
}
</style>
